@tailwind base;
@tailwind components;
@tailwind utilities;
@layer components {
  #__next {
    @apply h-full;
  }
  html,
  body {
    @apply h-full;
    overflow-x: hidden;
  }
  body:not(.focus-visible) :focus:not(.focus-visible) {
    outline: 0 !important;
    box-shadow: none !important;
  }
  h1 {
    @apply text-2xl;
  }
  h2 {
    @apply text-lg;
  }
  h3 {
    @apply text-base;
  }
  h4 {
    @apply text-base;
  }
  h5 {
    @apply text-base;
  }
  h6 {
    @apply text-base;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    @apply font-bold;
  }

  .background-shine {
    background-size: 400%;

    -webkit-animation: backgroundShine 3s ease infinite;
    -moz-animation: backgroundShine 3s ease infinite;
    animation: backgroundShine 3s ease infinite;
  }

  @keyframes backgroundShine {
    0%,
    100% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
  }

  .animation-pulse {
    /*box-shadow: 0 0 0 20px rgba(229,62,62, 0.5);
    transform: scale(0.8);*/
    animation: pulse 2s infinite;
  }

  @keyframes pulse {
    0% {
      transform: scale(0.9);
      box-shadow: 0 0 0 0 rgba(234, 88, 12, 1);
    }

    70% {
      transform: scale(1);
      box-shadow: 0 0 0 20px rgba(229, 62, 62, 0);
    }

    100% {
      transform: scale(0.9);
    }
  }
}
